@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

@import "@fontsource/roboto-mono/400.css";
@import "@fontsource/roboto-mono/500.css";
@import "@fontsource/roboto-mono/700.css";

@import "@fontsource/noto-sans/400.css";
@import "@fontsource/noto-sans/500.css";
@import "@fontsource/noto-sans/600.css";
@import "@fontsource/noto-sans/700.css";

@import "@fontsource/noto-sans-mono/400.css";
@import "@fontsource/noto-sans-mono/500.css";
@import "@fontsource/noto-sans-mono/700.css";

@font-face {
  font-family: "Gramatika";
  font-weight: 300;
  src: url("/public/fonts/GramatikaLight.woff");
}
@font-face {
  font-family: "Gramatika";
  font-weight: 400;
  src: url("/public/fonts/GramatikaRegular.woff");
}
@font-face {
  font-family: "Gramatika";
  font-weight: 700;
  src: url("/public/fonts/GramatikaBold.woff");
}

:root {
  --scrollBarWidth: 0;
}

body {
  margin: 0;
  font-family: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-size: inherit;
  margin: 0;
  padding: 0;
}

.stop-scrolling {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-right: var(--scrollBarWidth);
}

#WEB3_CONNECT_MODAL_ID {
  position: absolute;
  z-index: 22;
}
